$ITEM_W: 20em;
$ITEM_H: 15em;
$ITEM_IMG_H: 11em;
$ITEM_META_H: $ITEM_H - $ITEM_IMG_H;

.blog-posts {
  display: flex;
  flex-flow: row wrap;
  justify-content: start;

  .old {
    opacity: 0.5;
  }

  &__item {
    width: 20em;
    height: $ITEM_H;
    margin: .6em .3em auto .3em;

    display: flex;
    flex-direction: column;

    
    @include media-query(1040px) {
      flex-grow: 1;
      flex-basis: 100%;
    }

    &__img {
      height: $ITEM_IMG_H;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      text-align: center;
      color: black;
    }

    &__info {
      background: lightgray;
      height: $ITEM_META_H;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      p {
        margin: auto;
        width: 33%;
        text-align: center;
      }
      a {
        margin: auto;
        width: 33%;
        text-align: center;
      }
    }
  }

  .btn btn--dark {
    margin-top: 2em;
  }
}

/* .blog-posts {
  max-width: 1000px;
  padding: 0 20px;

  .blog-post-item {
    color: $dark-text;
    text-decoration: none;
    &__meta {
      margin-top: -30px;
      text-align: center;
      @include media-query($on-palm) {
        margin: 0px auto 20px;
      }
    }
  }
}*/

.blog-post {
  &__meta {
    margin: -30px auto 60px !important;
    text-align: center;
    @include media-query($on-palm) {
      margin: 0px auto 20px !important;
    }
  }

  .btn {
    display: block;
  }

  &__body {
    table {
      border-collapse: collapse;
      border-width: 1px;
      border-color: #000000;
      border-style: solid;

      td, th {
        border-width: 1px;
        border-color: #000000;
        border-style: solid;
        padding: 5px;
      }
    }
  }
}
