@use "variables" as *;

#cookie-notice {
  padding: 0.5rem 1rem;
  display: none;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: calc(100% - 2rem);
  background: $darkgray;
  color: $light-grey;
}

#cookie-notice a {
  display: inline-block;
  cursor: pointer;
  margin-left: 0.5rem;
}

@media (max-width: 767px) {
  #cookie-notice span {
    display: block;
    padding-top: 3px;
    margin-bottom: 1rem;
  }

  #cookie-notice a {
    position: relative;
    bottom: 4px;
  }
}
