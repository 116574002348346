@use "variables" as *;

.page-section.home {
  box-sizing: border-box;
  padding: 0 1em;
}

.featured-items {
  max-width: $content-width-wide;
  width: 95%;
  display: flex;
  flex-direction: column;

  .btn {
    &:hover {
      background-color: $light-grey;
    }
  }
}

.hero {
  text-align: center;
  margin: 5rem auto 7rem;
  max-width: $content-width;
  padding: 0 10px;
  width: 100%;

  @include media-query($on-palm) {
    margin: 20px auto;
  }


  flex-shrink: 1;

  &__title {
    font-size: 2.5rem;
    font-weight: 800;
    letter-spacing: 0.1em;
    line-height: 38px;
    text-transform: uppercase;
    margin: 1em auto;

    // TODO: Convert to variable media query
    @media screen and (max-width: 450px) {
      font-size: 35px;
      letter-spacing: 1px;
    }

    &--small {
      font-size: 16px;
      letter-spacing: 5px;
      font-weight: 400;
      margin-bottom: 10px;
    }
  }

  &--light {
    color: #ffffff;
  }

  .btn {
    //margin-bottom: 1em;
  }
}

.page-section {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }
}

.unit-choice {
  background-color: $blue;
  padding: 50px;
  width: 600px;
  margin: 30px;
  margin-top: 0;
  margin-top: 0;
  text-align: center;

  .btn {
    color: $dark-text;
    border: $dark-text solid;

    &:hover {
      background-color: #ffffff;
      color: $dark-text;
      border: $dark-text solid;
    }
  }

  h1 {
    color: #ffffff;
    line-height: 100%;
  }
}
