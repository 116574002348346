@use "variables" as *;

.social-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-content: stretch;
  max-width: $content-width;
  width: 100%;
  padding-bottom: 2.5rem;

  .social-links__item {
    width: 8rem;
    height: 8rem;
    margin: 10px 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 0.15s;
    border-radius: 0.5rem;
    position: relative;

    color: #ffffff;
    text-decoration: none;

    &--facebook {
      background: #3b5998;
    }
    &--twitter {
      background: #1da1f2;
    }
    &--github {
      background: #24292e;
    }
    &--linkedin {
      background: #4875b4;
    }
    &--youtube {
      background: #c4302b;
    }

    &--instagram {
      background: linear-gradient(45deg, #405de6, #5851db, #833ab3, #c13584, #e1306c, #fd1d1d);
    }

    &--discord {
      background: #7289da;
    }

    &--newsletter {
      background: #44c765;
    }

    &--strava {
      background: #fc5200;
    }

    &--rss {
      background: #fda333;
    }

    &--tiktok {
      background: rgba(254, 44, 85, 1);
    }

    &--calendar {
      background: #2d32ad;
    }

    /*&:after {
      content: attr(title);
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%) translateY(125%);
      background: $darkgray;
      padding: 0.25em 1em;
      border-radius: 5px;
      font-size: 0.75rem;
      opacity: 0;
      transition: all ease-out 0.3s 0.15s;
      z-index: -18;
    }

    &:hover:after {
      opacity: 1;
    }*/

    &:hover, &:focus {
      box-shadow: 0px 10px 10px $shadow;
      transform: translateY(-1px) scale(1.02);
      z-index: 9999999999;
    }

    i {
      font-size: 3rem;
    }

    img {
      height: 40px;
      width: 40px;
    }
  }
}

.links-holder {
  border-radius: 1rem;

  background-repeat: no-repeat;
            background-position: bottom right;
            background-size: contain;
            box-shadow: -1em 1em 2em rgba(0, 0, 0, 0.02), 1em -1em 2em rgb(255, 255, 255);

            background-color: #fff;
            background-image: url('/assets/images/contrib/sponsorship-page/standard-background.svg');
}

