.committee {
  display: flex;
  flex-direction: row;
  gap: 1rem 0.5rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
}

.committee-member {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0.5rem;
  width: 20ch;
  gap: 0.5rem;
  text-align: center;

  &__image {
    position: relative;
    width: 75%;
    height: 100%;
  }

  &__image:after {
    content: "";
    display: block;
    padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
  }

  &__image img {
    position: absolute; /* Take your picture out of the flow */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; /* Make the picture taking the size of it's parent */
    width: 100%; /* This if for the object-fit */
    height: 100%; /* This if for the object-fit */
    object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
    object-position: center;
    border-radius: 100%;
  }


  &__email {
    a {
      color: $medium-text;
      transform: scale(1);
      transition:
        transform ease 200ms,
        color ease 200ms;
        font-weight: 400;

      &:hover {
        transform: scale(1.25);
        color: $blue;
      }
    }
  }

  &__socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    font-size: 1.125rem;

    a {
      color: $medium-text;
      transform: scale(1);
      transition:
        transform ease 200ms,
        color ease 200ms;

      &:hover {
        transform: scale(1.25);
        color: $blue;
      }
    }
  }
}

.idea {
  border: 1px solid #999999;
  border-radius: 3px;
  padding: 10px;

  h2 {
    margin-top: 5px;
  }

  ul.idea__statuses {
    li.idea-status {
      list-style-type: none;
      margin: 10px 0;
      padding: 10px;

      h3 {
        margin-top: 5px;
      }

      &--in-progress {
        background-color: #fffdaa;
      }

      &--stalled {
        background-color: #ffd89e;
      }

      &--rejected {
        background-color: #ffa293;
      }

      &--completed {
        background-color: #c3ff99;
      }

      &--proposed {
        background-color: #e5e5e5;
      }
    }
  }
}

.btn, a.btn {
  border: 4px solid  $dark-pink;
  border-radius: 3px;
  color: #999;
  display: inline-block;
  font-size: 1em;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0.25em 0;
  padding: 0.5em 1em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .2s;

  &--light, &.light {
    background-color: #fff;
    color: $dark-pink;
  }

  &--dark, &.dark {
    background-color: $dark-pink;
    color: #fff;
  }

  &:hover, &:focus {
    background-color: $dark-purple;
    border-color: $dark-purple;
    color: #fff;
  }

  &:focus {
    transform: scale(0.8);
    outline: 2px solid $dark-pink;
  }

  &.slide {
    z-index: 1;
    position: relative;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 0;
        height: 100%;
        background-color: $dark-purple;
        transition: width .25s ease 0s;
    }
    &:hover::before {
        transition: width .5s ease-in-out 0s;
        width: 100%;
    }

    &.dark:hover:not(:focus) {
        background-color: $dark-pink;
    }
    &.light:hover:not(:focus) {
        background-color: #fff;
    }
}
}


.intro {
  @include grid-container;
  margin: 2rem auto 6rem;

  .page-title, .intro-text {
      margin: 0;
      @include cols(12);
  }

  .page-title {
      font-size: 3em;
      background: linear-gradient(to left, $dark-pink, lighten($dark-purple, 10%));
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      @media screen and (max-width: $on-palm) {
          font-size: 2em;
          margin-bottom: 0.5em;
      }
  }
  .intro-text {
      p {
          margin: 0 auto 1em;
      }
      .link-list {
          margin-top: 1em;
          .btn:first-child {
              margin-right: 1em;
          }
      }
  }
}

.bold-text {
  font-weight: bold;
}

.grey-bg {
  width: 100%;
  // gradient so that margin above footer doesn't look like solid white bar
  background: linear-gradient(to bottom, $light-grey, #fff);
}

.no-margin {
  margin-top: 0;
}