h1 {
  text-transform: uppercase;
}

h1, h2, h3, h4, h5, h6,
.page-section h1, .page-section h2, .page-section h3, .page-section h4, .page-section h5, .page-section h6{
  font-family: $headfont;
  font-weight: 800;
  margin: 1.5em auto 1em;
}

// Acumin Pro for .acumin
// @import url("https://use.typekit.net/gtw0nwa.css");
