$pacblack:  #040204;
$pacsalmon: #040204;
$pacwhite:  #dcdedc;
$pacred:    #fc0204;
$pacyellow: #fcfe04;
$pacblue:   #fcfe04;
$pacpink:   #fcb6dc;
$pacorange: #fcb644;
$pacblue:   #2422dc;

body {
    margin: 0;
}

#explorecont {
    display: grid;
    grid-template: 1fr / 1fr minmax(10%, 960px) 1fr;
    background: $pacblack;
    font-family: 'Press Start 2P', cursive;

    .side {
        background: url(/assets/images/contrib/events/2018-11-explore/bg.jpg);
        width: 80%;
    }
    #left { margin-right: 20%; background-position-x: right; }
    #right { margin-left:20%; }

    #middle {
        grid-area: 1 / 2 / 2 / 3;
        color: $pacwhite;
        img {
            display: block;
            margin: 0 auto;
            max-width: 80%;
            padding: 2em;
        }
        h1 {
            font-size: xx-large;
        }
        #maintext {
            padding: 2em;
            margin-bottom: 2em;
            background: $pacred;
        }
    }
}

#eventcont {
    display: flex;
    flex-direction: column;
    
    div:nth-child(even) {
        align-self: flex-end;
    }
    div:nth-child(odd) {
        align-self: flex-start;
    }

    .event {
        display: grid;
        grid-template: 13.5em 1fr / minmax(100%, 24em);
        border: 5px solid $pacblue;
        border-radius: 0.5em;
        overflow: hidden;
        color: $pacblack;
        max-width: 100%;
        margin-bottom: 2em;

        .image {
            grid-area: 1 / 1 / 2 / 2;
            background: slategray;
            height: 100%;
        }

        .fb {
            grid-area: 1 / 1 / 2 / 2;
            background: url(/assets/images/contrib/events/2018-11-explore/flogo-HexRBG-Wht-100.png);
            background-size: contain;
            width: 60px;
            height: 60px;
            z-index: 2;
        }
        .desc {
            grid-area: 2 / 1 / 3 / 2;
            background-color: $pacwhite;
            height: 100%;

            .info {
                display: flex;
                justify-content: space-evenly;
                border-bottom: 1px solid gray;
                background: $pacpink;
                
                p {
                    width: 50%;
                    text-align: center;
                    line-height: 2em;
                    font-weight: bold;
                }
            }
            .text {
                padding: 1em;
            }
        }
    }
}