@charset "utf-8";

// Width of the content area
$content-width: 900px;
$content-width-wide: 1000px;
$on-palm: 900px;
$on-laptop: 1000px;

// Fonts
$headfont: "Open Sans", sans-serif;
$bodyfont: "Open Sans", sans-serif;
$thinfont: "Open Sans", sans-serif;
$monospacefont: "JetBrains Mono", monospace;

// Colours
$light-grey: #f0f0f0;
$dark-text: #333333;
$medium-text: #666666;
$shadow: #cccccc;

$white: #ffffff;
$purple: #9b59b6;
$green: #2ecc71;
$red: #e74c3c;
$blue: darken(#3498db, 20);
$orange: #ea5d00;
$turquoise: #1abc9c;
$yellow: #f39c12;
$darkgray: #333333;
$gray: #666666;
$salmon: #fa8a90;
$salmon-light: #fa9ba1;
$dark-pink: #D30055;
$desaturated-pink: #C63B63;
$dark-purple: #220021;

// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// Import partials from `sass_dir` (defaults to `_sass`)

// Todo: only used for sponsorship page
@import "grid";

@import "accessibility";
@import "events";
@import "meyer-reset";
@import "blocks";
@import "fonts";
@import "sections";
@import "articles";
@import "blog-posts";
@import "card-grid";
@import "blockquotes";
@import "tutorials";
@import "job-posts";
@import "components";
@import "home";
@import "calendar";
@import "nav";
@import "syntax-highlighting";
@import "explore";
@import "cookie-consent";
@import "acumin";
@import "social_links";
@import "tooltip";
@import "pager";
@import "agm-event";
@import "sponsor";
@import "freshers"