@use "variables" as *;

blockquote {
  width: 90%;
  margin: 1rem 5%;
  border-left: 0.5rem solid $gray;
  padding-left: 1rem;
  color: $dark-text;

  .citation {
    display: block;
    text-align: right;
    font-size: 0.9rem;
    font-style: italic;
    margin-left: auto;

    &:before {
      content: '-';
      margin-right: 0.5rem;
    } 
  }
}
