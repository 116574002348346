@use "variables" as *;

.article {
  max-width: $content-width;
  padding: 0 20px;
  text-align: left;
  overflow: hidden;

  &.wide {
    max-width: $content-width-wide;
  }

  &.extrawide {
    max-width: 1.2*$content-width-wide;
  }

  a {
    color: $blue;
    font-weight: 700;
    border-bottom: 2px solid transparent;
    text-decoration: none;
    transition: border-bottom .2s;

    &:hover {
      border-bottom: 2px solid $blue;
    }
  }

  ol {
    margin: 1em 0;

    li {
      list-style-type: decimal;
      list-style-position: inside;
      text-indent: 0px;

      @include media-query($on-palm) {
        text-indent: 1em;
      }
    }

    ul,
    ol {
      margin-left: 1em;
    }
  }

  ul {
    margin: 1em 0;

    li {
      list-style-type: disc;
      list-style-position: inside;
      text-indent: 0px;

      @include media-query($on-palm) {
        text-indent: 10px;
      }

    }

    ul li {
      list-style-type: "- ";
    }

    ul,
    ol {
      margin-left: 1em;
    }
  }

  pre {
    font-family: $monospacefont;
    padding: 5px;
    min-width: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  code {
    font-family: $monospacefont;
    min-width: 0;

    &:not(pre > code) {
      display: inline-block;
      background-color: #eef;
      border: 1px solid darken(#eef, 5%);
      border-radius: 1px;
      padding: 0 3px;
    }
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }
}

.aligned-image {
  text-align: center;
}

.page-section {
  width: 100%;
}