@use "variables" as *;

.card-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  padding-top: 15px;

  &__card {
    margin: 0px 12.5px 25px 12.5px;
    padding: 2rem 1rem;
    width: 250px;
    box-shadow: 0px 0px 10px $shadow;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    transition: transform ease-in-out 0.275s;

    &:hover {
      transform: translateY(-1px) scale(1.02);
    }

    &__banner {
      width: auto;

      /* Set height to be aspect ratio of 16:9 */
      padding-top: 56.25%;
      margin: -2rem -1rem 1rem -1rem;
      border-radius: 10px 10px 0px 0px;
      background: no-repeat center center scroll;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }

    &__row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      .fas, .fab, .far {
        font-size: 1.5rem;
        color: $darkgray;
        width: 1.5rem;
        text-align: center;
      }

      p {
        line-height: 1.5rem;
      }
    }


    &__list {
      text-align: left;
      line-height: 2rem;
      word-break: normal;
    }

    &__footer {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      padding-top: 10px;
      margin-top: auto;

      &__next-btn {
        all: unset !important;
        font-size: 1.5rem !important;
        width: 2.5rem !important;
        height: 2.5rem !important;
        border-radius: 2.5rem !important;
        line-height: 2.5rem !important;
        text-decoration: none !important;
        color: $white !important;
        background-color: $salmon !important;
        text-align: center !important;
        transition: all ease-in-out 0.25s !important;
      }

      &__next-btn:hover {
        background-color: $salmon-light !important;
      }

      &__next-btn:focus {
        background-color: $salmon-light !important;
        border: 1px solid $salmon !important;
      }
    }
  }

  p {
    margin: unset;
    text-align: right;
  }

  h3 {
    margin: unset;
    margin-bottom: 15px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
  }
}
