.job-posts {
  display: grid;
  width: 90%;
  margin: 0 auto;
  column-gap: 2em;
  grid-template-columns: repeat(1, 1fr);

  @media screen and (min-width: $container-breakpoint-md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: $container-breakpoint-lg) {
    grid-template-columns: repeat(4, 1fr);
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  &__item {
    &--sponsored {
      // width: 90%;
    }
  }

  .job-post-item {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 1em;
    margin: 1em 0;

    box-shadow: 0px 0px 10px $shadow;
    border-radius: 3px;
    border-left: 0 solid rgb(75, 0, 113);
    color: $dark-text;
    min-height: 250px;
    text-decoration: none;

    order: 1;

    &__title {
      font-size: 24px;
      margin: 10px 0 10px;
    }

    &__date {
      text-align: left;

      @include media-query($on-palm) {
        margin: 0px auto 20px;
      }
    }

    &__company {
      font-weight: 400;
    }

    .company-logo {
      img {
        height: 3rem;
        object-fit: contain;
        object-position: bottom left;
        margin: 0;
      }
    }

    &--sponsored {
      order: 0;
      border-left-width: 1em;

      @media screen and (min-width: $container-breakpoint-md) {
        grid-column: span 2;
      }

      &:before {
        display: block;
        text-align: center;
        content: 'Sponsored';
        color: rgb(118, 118, 118);
        border-radius: 2em;
        font-size: 0.9em;
        font-weight: 800;
        text-transform: uppercase;
      }
    }

    p {
      margin: 5px 0;
    }
  }
}

.job-post {
  &__header {
    text-align: center;

    h1 {}

    img {
      margin: 10px;
      max-width: 200px;
    }
  }

  &__sponsored {
    background: #fa8a90;
    border-radius: 3px;
    color: #ffffff;
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    margin: 5px;
    padding: 5px 10px;
    text-transform: uppercase;
  }

  &__dates {
    margin: -20px auto 10px !important;
    text-align: center;
  }

  *[itemprop=hiringOrganization] {
    text-align: center;

    *[itemprop=address] {
      font-size: 12px;
    }

    p {
      margin: 4px 0;
    }
  }

  dl {
    margin: 20px 0;

    dt {
      display: inline-block;
      font-weight: 400;
      min-width: 150px;
    }

    dd {
      display: inline;

      &:after {
        display: block;
        content: '';
      }
    }
  }
}

.job-details {
  .company-logo img {
    height: 1.5rem;
    object-fit: contain;
    object-position: left center;
  }
}