html {
  scroll-behavior: smooth;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  font-family: $bodyfont;
}

.page-header {
  &.noscript {
    background: url('/assets/images/core/header-bg-03.jpg') no-repeat;
  }
  // Set solid colour background so that page still readable while image loads.
  background-color: #000;
  background-size: cover;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;

  padding: 20px 0;

  @include media-query($on-palm) {
    justify-content: center;
    width: 100%;
  }

  &__logo {
    display: block;
    background-image: url(/assets/images/core/logo-inline-light.png);
    background-size: contain;
    background-repeat: no-repeat;

    height: 50px;
    width: 25%;
    min-width: 350px;
    padding-left: 50px;

    @include media-query($on-palm) {
      padding: 0;
      background-image: url(/assets/images/core/logo-small-light.png);
      background-position: center;
    }

    // TODO: Convert to variable media query
    @media screen and (max-width: 450px) {
      // padding: 0;
      //min-width: 100%;
    }

    img {
      height: 45px;

     // TODO: Convert to variable media query
     @media screen and (max-width: 450px) {
        height: 25px;
      }
    }
  }
}

#page-header--random-bg {
  position: relative;
  .bg {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 120%;
      height: 120%;
      background-size: cover;
      background-position: center;
      &.blur {
        filter: blur(10px);
      }
    }
  }
  .navigation-items,
  .hero {
    z-index: 1;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.58);
  }
}

.page-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 50px;
  overflow: hidden;
  padding: 30px 0;

  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;

  @include media-query($on-palm) {
    margin-bottom: 20px;
    padding: 10px 0;
  }

  &--nopad {
    padding: 0 0;
  }

  &--nopad-bottom {
    padding-bottom: 0;
  }

  &--nopad-top {
    padding-top: 0;
  }

  p {
    margin: 16px 0;
  }

  h1 {
    font-size: 25px;
    margin: 50px 10px;
    max-width: 1000px;
    text-align: center;
    line-height: 1;

    @include media-query($on-palm) {
      margin: 20px 10px;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  h2 {
    font-size: 18px;
    margin-top: 40px;
    line-height: 1;
  }

  h3 {
    font-size: 16px;
    margin-top: 20px;
    line-height: 1;
  }

  img {
    margin: auto;
    display: block;
    max-width: 100%;
  }

  &--shaded {
    background: $light-grey;
  }
}

.sponsorsec {
  border-top: 1px lightgray solid;
}

.sponsors__item {
  h2 {
    font-size: 25px;
    font-weight: 400;
    max-width: 1000px;
    text-align: center;

    @include media-query($on-palm) {
      margin: 20px 10px;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }
}

.page-footer {
  background: $light-grey;
  color: $medium-text;

  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;

  font-size: 14px;
  padding: 20px 50px;

  @include media-query($on-palm) {
    padding: 20px 10px;
  }

  &__text {
    flex-grow: 1;
    @include media-query($on-palm) {
      text-align: center;
      width: 100%;
    }
  }

  &__menu {
    display: inline-flex;
    justify-content: flex-end;
    flex-grow: 2;
    @include media-query($on-palm) {
      display: none;
    }

    li {
      display: inline;
      list-style-type: none;
    }

    a {
      color: $medium-text;
      padding: 10px;
      text-decoration: none;
    }
  }
}
