.calendar {
  max-width: 1000px;
  margin: 0 20px;
  &__event {
    &--university {
      background-color: $purple !important;
      border: 1px solid $purple !important;
      padding: 0px 5px 5px !important;
      &:before {
        content: 'University';
        font-size: 8px;
        line-height: 8px;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
      }
    }
    &--css {
      background-color: $blue !important;
      border: 1px solid $blue !important;
      padding: 0px 5px 5px !important;
      &:before {
        content: 'CSS';
        font-size: 8px;
        line-height: 8px;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
      }
    }
    &--beees {
      background-color: $red !important;
      border: 1px solid $red !important;
      padding: 0px 5px 5px !important;
      &:before {
        content: 'BEEES';
        font-size: 8px;
        line-height: 8px;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
      }
    }
  }
  a {
    color: white;
  }
}
